.about-physician-container {
    display: grid;
    align-items: center;
    margin-bottom: 60px;
}

.about-physician-grid {
    margin: 0 2.875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.about-physician-grid > div {
    border: 2px solid #D9D9D7;
    margin: 10px;
    text-align: left;
    padding: 5%;
}

.grid-header{
    color: #235D86;
    font-size: 1.5rem !important;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0;
    margin-right: 15%;
}

.grid-text {
    padding-top: 10px;
    font-size: 1.125rem !important;
}

.grid-text ul {
    padding-top: -5px;
    list-style-position: outside !important;
    padding-inline-start: 10px;
}

.grid-text ul li{
    padding-top: 5px;
    list-style: none;
    padding-left: 1.3rem;
}

.grid-text ul li:before {
    content:"· ";
    font-size: 40px;
    vertical-align: text-top;
    line-height:20px;
    margin-left: -20px;
}

.about-physician-container > div { 
    margin-top: 10px;
    text-align: center;
    font-size: 1.2em;
}

.sub-header-text {
    font-weight: 700;
    font-size: 1.7em !important;
    color: #000000;
    margin-bottom: -10px;
}

.text-about {
    font-family: 'Oswald' !important;
    color: #235D86;
    font-size: 1.56rem !important;
    font-weight: 700 !important;
    margin-bottom: -10px;
}

.text-state-physician {
    color: #000000;
    font-size: 3.125rem !important;
    font-weight: 800;
    line-height: 3.563rem;
}

.text-description {
    text-align: center;
    color: #000000;
    font-size: 1.25rem !important;
    font-weight: 600;
    padding-right: 10% ;
    padding-left: 10%;
}

.complaint-button-container{
    padding-top: 30px;
    margin-top: 20px;
}

.complaint-button-container > div {
    text-align: center;
}

.complaint-button {
    color: #000000;
    width: 100%;
    font-size: 1.2em;  
    font-weight: 700;
}

.complaint-button.MuiButton-outlined {
    border: 2px solid #000000;
    border-radius: 0;
}

.complaint-button.MuiButton-root{
    font-weight: 700;
    font-size: 0.8em;
}

@media only screen and (max-width: 480px) {
    .about-physician-grid {
        display: grid;
        grid-template-columns: 1fr;
        margin: -2px;
    }
  }