
  .accordion-container {
    margin: auto;
    padding: 3rem 0;
    padding-bottom: 0;
  }

  .accordion-container > .MuiAccordion-root {
    margin-bottom: 1rem;
    padding-left: 1rem;
  }

  .accordion-container > .MuiAccordion-root.Mui-expanded {
    margin-bottom: 1rem;
  }

  .MuiAccordionDetails-root {
    padding: 0.5rem 4rem 1rem 2.9rem !important;
  }

  .MuiIconButton-label {
    color: #000000;
  }

  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  .addIcon {
    display: block !important;
  }
  .closeIcon {
    display: none !important;
  }

  .Mui-expanded .closeIcon {
    display: block !important;
  }
  .Mui-expanded .addIcon {
    display: none !important;
  }

  .accordion-summary {
    background-color: #f1f1f1;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 480px) {
    .MuiAccordionDetails-root {
      padding: 0.5rem 3rem 1rem 2.9rem !important;
    }
  }
