.container {
    display: grid;
    background-color: #235D86;
    color: #cfdbe3;
    align-items: center;
    padding: 10px 30px 50px 30px;
}

.container > div { 
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
}

.container > div > a { 
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1rem;
}

.website {
    font-family: 'Oswald' !important;
    font-weight: 600;
    font-size: 1.56rem !important;
    color: #F0F0F0;
    margin-bottom: -15px;
}

.disclaimer {
    color: #FACE00;
    font-size: 3.125rem !important;
    font-weight: 800;
}

.providers {
    font-family: 'Oswald' !important;
    font-weight: 600;
    font-size: 1.56rem !important;
    margin-top: 50px !important;
    margin-bottom: 20px;
    color: #f0f0f0;
}
