.back-to-top-btn {
    background-color: #FACE00 !important;
    border: 0;
    outline: 0;
    font-size: 12px;
    color: #000000;
    text-align: center;
    line-height: 16px;
    padding: 5px 10px;
    cursor: pointer;
    text-transform: none !important;
    position: fixed !important;
    top: 50%;
    right: 0;
}

.back-to-top-btn span {
    font-weight: 400;
}

.back-to-top-btn-icon {
    font-size: 25px;
}
 @media screen and (max-width: 640px) {
    .back-to-top-btn span {
        display: none;
    }
    .back-to-top-btn {
        top: initial;
        bottom: 0;
        right: 0;
    }
 }