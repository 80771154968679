* {
  box-sizing: border-box;
  font-family:"proxima-nova",'Oswald' !important;
  margin: 0;
  padding: 0;
  border: 0;
}

body, html {
  margin: 0;
  font-family: "proxima-nova",'Proxima Nova Extrabold' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: 0;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Common Classes */

.font20px {
  font-size: 20px !important;
}
.font24px {
  font-size: 24px !important;
}
.font16px {
  font-size: 16px !important;
}
.font18px {
  font-size: 18px !important;
}

.colorBlue {
  color: #235d86 !important;
}
.colorBlack{
  color:#000000;
}
.colorGrey{
  color:#424242 !important;
}
.colorYellow{
  color:#FACE00 !important;
}

.fontW800 {
  font-weight: 800;
}
.fontW700 {
  font-weight: 700;
}
.fontW600 {
  font-weight: 600;
}
.fontW500 {
  font-weight: 500;
}
.fontW400 {
  font-weight: 400;
}
.paddingTop30px{
  padding-top:30px;
}
.paddingTop20px{
  padding-top:20px;
}
.paddingTop10px{
  padding-top:10px;
}
.padding0px{
  padding: 0px !important;
}
.paddingBot20px{
  padding-bottom:20px;
}
.paddingBot30px{
  padding-bottom:30px;
}

.btn-link {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0 !important;
  line-height: 0.75 !important;
  min-width: 0 !important;
}
.textTransform{
  text-transform: none !important;
}
.textUnderlined{
  text-decoration: underline !important;
}
.cursor-pointer{
  cursor: pointer;
}


