
.nav-links a, .nav-links a:link {
    text-decoration: none;
    color: #000000;
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    padding: 0 5px;
}

.nav-links a:hover, .nav-links a:active {
    color: #235D86;
}

@media only screen and (min-width: 1000px) {
    .nav-links a, .nav-links a:link {
        padding: 0 10px;
    }

    .nav-links {
        padding-left: 15px;
    }
}

.mobile-menu {
    width: 250px;
}

.mobile-nav-btn.MuiIconButton-root {
    display: none;
}

@media only screen and (max-width: 800px) {
    .mobile-nav-btn.MuiIconButton-root {
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 1000;
    }

    .MuiToolbar-regular.MuiToolbar-root.nav-toolbar {
        display: none;
    }

    .MuiToolbar-regular.MuiToolbar-root.mobile-nav-toolbar.nav-toolbar {
        display: block;
    }
}

.nav-toolbar {
    display: block;
}

.MuiToolbar-regular.MuiToolbar-root.mobile-nav-toolbar {
    display: none;
}

.mobile-menu > .MuiList-root > .MuiListItem-root > a,
.mobile-menu > .MuiList-root > .MuiListItem-root > a:link {
    text-decoration: none;
    color: #000000;
    font-family: "Proxima Nova";
    font-size: 1em;
    letter-spacing: 0;
    line-height: 25px;
    padding: 0 5px;
}

.mobile-menu > .MuiList-root > .MuiListItem-root > a:hover,
.mobile-menu > .MuiList-root > .MuiListItem-root > a:active {
    color: #235D86;
}

.mobile-menu > .MuiList-root > .MuiListItem-root > a.physical-login-btn {
    background-color: #FACE00;
    border: 0;
    outline: 0;
    font-size: 15px;
    color: #000000;
    text-align: center;
    font-weight: 800;
    line-height: 25px;
    padding: 10px 15px;
    cursor: pointer;
    text-transform: capitalize;
}

.mobile-menu > .MuiList-root > .MuiListItem-root  .MuiIconButton-label {
    justify-content: flex-start;
}


.nav {
    background-color: white !important;
}
.nav-links {
    padding-left: 20px;
}

.login-btn {
    background-color: #FACE00 !important;
    color: black !important;
    font-weight: 600;
    /* height: 112px; */
}

.logo-link {
    color: #FACE00 !important;
    width: 230px;
}
.MuiToolbar-root.nav-toolbar{
    padding: 0 !important;
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;
}

.nav-toolbar > .sections {
    padding: 10px 0;
}

.nav-toolbar > .sections:first-of-type {
    flex-basis: 20%;
    min-width: 200px;
    border-right: 1px solid #D9D9D7;
    
}

.nav-toolbar > .sections:first-of-type  img {
    width: 109px;
    height: auto;
}

.nav-toolbar > .sections:nth-of-type(2) {
    flex-basis: 62%;
    display: flex;
    align-items: center;
}

.nav-toolbar > a.sections:last-of-type {
    flex-basis: 16%;
    min-width: 200px;
    background-color: #FACE00;
    border: 0;
    outline: 0;
    font-size: 20px;
    color: #000000;
    text-align: center;
    font-weight: 800;
    line-height: 25px;
    text-transform: capitalize;
}
.skip-to-main{
    color: transparent;
    font-size: 10px;
    position: absolute;
}
.skip-to-main:focus{
    color: white;
    background-color: #235D86;
}